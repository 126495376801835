@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,600;1,600&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

*{
    box-sizing: border-box;
}

.input-text{
    @apply font-helvetica font-bold bg-transparent border border-2 border-l-0 border-r-0 border-t-0 border-black focus:outline-none text-black py-1 my-2;
}

.social-media-icon{
    @apply text-2xl text-black mx-5 sm:my-5 sm:block;
}

.facebook-icon:hover{
    color: #1877F2;
}

.twitter-icon:hover{
    color: #1DA1F2;
}

.instagram-icon:hover{
    color: #E1306C;
}

.linkedin-icon:hover{
    color: #0077B5;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.background{
    background-color: white;
}

.main-container{
    background-color: #d9d9d9;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.submit-button{
    background: linear-gradient(to left, black 50%, #5c7c89 50%) right;
    background-size: 200%;
    transition: .5s ease-out;
}

.submit-button:hover{
    background-position: left;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
